.faq-card-nav {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  color: #fff;
  background: none;
  font-size: 21px;
  font-weight: 700;
  border-radius: 6px;


  @include media("<1200px") {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    +* {
      margin-top: 10px;
    }
  }
  &.selected {
    background-color: $primaryColor;
    color: #fff;

  }
}
.faq-nav-wrap {
  display: flex;
  padding-bottom: 15px;

  @include media("<1200px") {
    display: block;
  }
}
.faq-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  >* {
    width: calc(100% / 3);
    padding: 0 10px;
    padding-bottom: 20px;

    @include media("<1200px") {
      width: 100%;
    }
  }
}
.faq-item {
  &__title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 14px;
  }
  &__text {
    font-size: 11px;
    font-style: italic;
    opacity: .75;
  }
}