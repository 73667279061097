.footer {
  width: 100%;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }

  &__info {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: $white;
    &::selection {

    }
  }

  &__menu {
    position: relative;
    height: 31px;
    margin: 0;
    padding: 0 0 0 37px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      height: 100%;
      width: 0;
      border-right: 1px solid rgba($grayFooterText, 0.1);
    }
    &-item {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: $grayFooterText;
      margin: 0 20px 0 0;

      a {
        color: $grayFooterText;
        &:hover {
          color: $secondaryColor;
        }
      }

      &:hover {
        a::after {

        }
      }
    }
  }

  @include maxWidth(1074px) {
    height: fit-content;
    &__wrapper {
      padding: 26px 32px 25px;
      flex-direction: column;
    }
    &__menu {
      height: fit-content;
      margin: 0 0 20px;
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;
      &:before {
        display: none;
      }
      &-item {
        margin: 0 15px 10px 0;
      }
    }
    &__info {
      order: 2;
      margin-top: 10px;
    }
  }

  @include maxWidth(500px) {
    &__wrapper {
      padding: 26px 16px 25px;
    }
    &__menu {
      flex-direction: column;
      flex-wrap: nowrap;
      &-item {
        margin: 0 0 10px 0;
      }
    }
  }
}