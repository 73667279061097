.how-it-work-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0 -15px;
  &__item {
    width: calc(100% / 4);
    padding: 0 15px;
    margin-bottom: 30px;
    position: relative;
    
    @include media('<=860px') {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }

    &::before {
      content: "";
      width: 18px;
      height: 18px;
      background: url("../../assets/images/outer/right-arrow.svg") no-repeat center / contain;
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);

      @include media('<=860px') {
        display: none;
      }
    }
    
    &:last-child {
      &::before {
        display: none;
      }
    }
  }

}
.how-it-works-card {
  background-color: $darkBox;
  font-weight: 700;
  font-size: 15px;
  border-radius: 10px;
  height: 270px;
  text-align: center;
  padding: 30px;
  font-weight: bold;

  &__icon {
    height: 165px;

    img {
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    max-width: 145px;
    margin: 0 auto;
  }
}